$header-height: 65px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout {
  .ant-layout-header {
    height: $header-height;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .height-calc {
    height: calc(100vh - #{$header-height});
  }
}
